<template>
  <div class="page">
    <div class="top">
      <div class="title">特点</div>
      <div class="eng">· 低代码平台 ·</div>
    </div>
    <div class="content hvr-glow wow animate__slideInUp">
      <div class="point">
        <div class="img">
          <img
            src="../../../assets/images/point01.png"
            class="point-icon hvr-pulse wow animate__fadeInLeft"
          />
        </div>
        <div class="left">
          <div class="left-title">减少人员的投入，快速完成项目、迭代升级</div>
          <div class="left-data">
            统一的框架可以专门成立一个小组，专门对框架进行测试维护升级，让项目组把精力更多的投入到业务中，
            避免每个项目都独自去解决遇到的各种各样的技术难题，有效的把精力释放出来。
          </div>
        </div>
      </div>
      <div class="point">
        <div class="img">
          <img
            src="../../../assets/images/point03.png"
            class="point-icon hvr-pulse wow animate__fadeInLeft"
          />
        </div>
        <div class="left">
          <div class="left-title">软件开发规范化，快速培训</div>
          <div class="left-data">
            框架将软件开发规范化，削弱了个人的影响力，保证项目核心人员的离开有人站出来顶替他的位置，
            继续完成任务。框架虽然限制了个人的技术发展，却提升了公司的整体水平。
          </div>
        </div>
      </div>
      <div class="point">
        <div class="img">
          <img
            src="../../../assets/images/point02.png"
            class="point-icon hvr-pulse wow animate__fadeInLeft"
          />
        </div>
        <div class="left">
          <div class="left-title">促进技术交流，提升产品质量</div>
          <div class="left-data">
            统一的开发框架对团队有相同的交流对象，良好的交流不仅可以提升技术，对框架也是非常有帮助的。
            采用统一的开发框架（平台）后，在技术栈，技术组件，技术实现方案，甚至在代码规范上就能形成标准化的技术输出模式，
            标准化带来的最大效果不仅仅开发效率的快速提升
          </div>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="title">支持单体与cloud</div>
      <div class="eng">SUPPORT MONOMER AND CLOUD</div>
    </div>
    <div class="cloud hvr-glow wow animate__slideInUp">
      <div class="padding-img">
        <img src="../../../assets/images/E04.png" class="cloud-img" />
      </div>
    </div>
    <div class="top">
      <div class="title">系统架构</div>
      <div class="eng">SYSTEM ARCHITECTURE</div>
    </div>
    <div  class="cloud hvr-glow wow animate__slideInUp">
      <div class="padding-img">
        <img src="../../../assets/images/E05.png" class="cloud-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CodePlatform",
  mounted() {
    this.$wow.init();
  },
};
</script>

<style scoped lang="scss">
.animated {
  animation-duration: 1500ms        //动画持续时间
}
.page {
  width: 100%;
  height: 100%;
  background-color: #eff3fb;
  text-align: center;
  padding: 30px 0;
  .top {
    margin-bottom: 40px;
    .title {
      color: #4f85e1;
      font-size: 18px;
    }

    .eng {
      letter-spacing: 5px;
      color: #9b9da1;
      margin-top: 15px;
      font-size: 12px;
    }
  }
  .content {
    width: 70%;
    margin-left: 15%;
    background-color: #fff;
    padding: 20px 0;
    margin-bottom: 50px;
    display: block;
    .point:last-child {
      padding-bottom: 20px;
    }
    .point {
      display: flex;
      padding: 40px 40px 0 40px;
      .point-icon {
        height: 60px;
        width: 66px;
      }
      .img {
        display: flex;
        align-items: center;
      }
    }
    .left {
      text-align: left;
      margin-left: 40px;
      .left-title {
        font-size: 18px;
        color: #4f85e1;
      }
      .left-data {
        margin-top: 30px;
        color: #6e6e6e;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .cloud {
    width: 70%;
    margin-left: 15%;
    background-color: #fff;
    margin-bottom: 50px;
    display: block;
    .padding-img {
      padding: 20px;
    }
    .cloud-img {
      width: 100%;
    }
  }
}
</style>
