<template>
  <div class="page">
    <div class="main-box">
      <div class="main-list">
        <div class="text-box " @click="tap('datasystem')">
          <span v-if="isShow === 'datasystem'" class="text-icon1"></span>
          <div class="text">主数据系统</div>
        </div>
        <div class="text-box" @click="tap('planning')">
          <span v-if="isShow ==='planning'" class="text-icon1"></span>
          <div class="text">计划系统</div>
        </div>
        <div class="text-box" @click="tap('valuesystem')">
          <span v-if="isShow === 'valuesystem'" class="text-icon1"></span>
          <div class="text">货值系统</div>
        </div>
        <div class="text-box" @click="tap('procurement')">
          <span v-if="isShow === 'procurement'" class="text-icon1"></span>
          <div class="text">招采系统</div>
        </div>
        <div class="text-box" @click="tap('cost')">
          <span v-if="isShow === 'cost'" class="text-icon1"></span>
          <div class="text">成本系统</div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "CaseMenu",
  components: {},
  props: {},
  data() {
    return {
      isShow: "datasystem",
    };
  },
  methods: {
    tap(data) {
      this.isShow = data;
      if (data === "datasystem") {
        this.$router.push("/thesolution/propertyplatform/datasystem");
      } else if (data === "planning") {
        this.$router.push("/thesolution/propertyplatform/planningsystem");
      } else if (data === "valuesystem") {
        this.$router.push("/thesolution/propertyplatform/valuesystem");
      } else if (data === "procurement") {
        this.$router.push("/thesolution/propertyplatform/procurementsystem");
      } else if (data === "cost") {
        this.$router.push("/thesolution/propertyplatform/costsystem");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  width: 100%;
  background-color: #eff3fb;
  .main-box {
    width: 70%;
    margin-left: 15%;
    .main-list {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .text-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        .text {
          font-size: 14px;
          margin-left: 10px;
          display: flex;
          align-items: center;
        }
        .text-icon1 {
          width: 6px;
          height: 6px;
          background-color: #2165d9;
          display: inline-block;
          border-radius: 50%;
        }
        .text-icon2 {
          width: 6px;
          height: 6px;
          background-color: #eff3fb;
          display: inline-block;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>