<template>
  <div >
    <TheHeader class="head"></TheHeader>
    <div class="showImg">
      <el-carousel height="150px" :interval="2000" :arrow="'never'" >
        <el-carousel-item v-for="(item,index) in imgArr" :key="index">
          <img
            :src="item.url"
            alt="暂无图片"
            class="img"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
    export default {
        name: "HomePage",
        data() {
            return {
                imgArr: [
                    {
                        id: 0,
                        url: require("../assets/images/1.png")
                    },
                    {
                        id: 1,
                        url: require("../assets/images/2.png"),
                    },
                    {
                        id: 2,
                        url: require("../assets/images/3.png"),
                    },
                ],
            };
        },
        methods: {
        },
        //进入页面后启动定时轮询
        mounted() {
        },
    };
</script>

<style scoped>
  * {
    padding: 0;
    margin: 0;
  }

  .head{
    position: fixed;
    z-index: 999;
  }
  .showImg {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 998;
  }
  /* 轮播图片 */
  .img {
    width: 100%;
    height: 100vh;
  }

  ::v-deep .box {
    position: fixed;
    background-color: rgb(255, 255, 255, 0.4);
    color: #fff;
  }
  ::v-deep .box .right .el-menu-item :hover {
    background-color: unset;
  }
  ::v-deep .box .right .el-menu-item {
    color: #fff;
  }
  ::v-deep .box .right .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #fff !important;
  }
  ::v-deep .box .left .logo .logo-right p span {
    color: #fff !important;
  }
  ::v-deep .box .left .logo .logo-center {
    background-color: #fff !important;
  }
  ::v-deep .el-carousel__container{
    height: 100vh !important;
    width: 100% !important;
  }
  ::v-deep .el-carousel__button{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #2d5686;
  }
  ::v-deep .is-active button{
    background-color: #3291f8;
  }
</style>

