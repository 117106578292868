<template>
  <div class="page">
    <div class="top">
      <div class="title">企业介绍</div>
      <div class="eng">COMPANY PROFILE</div>
    </div>
    <div class="content">
      <div class="bottpm-contant">
        <img src="../../assets/images/E01.png" class="img " />
        <div class="company">
          <div class="left">
            <div class="title">企业理念</div>
            <div class="line"></div>
            <div class="mes">
              <div>信睿,本着"<span>担当、精益、公平、共享</span>"的价值发展理念稳步发展。</div>
              <div>业务和技术一体化,增强企业竞争力。TM</div>
            </div>
            <div class="icon-mes">
              <div class="icon-menu">
                <img src="../../assets/images/E03.png" class="icon hvr-pop" />
                <div class="menu-name">担当</div>
              </div>
              <div class="icon-menu">
                <img src="../../assets/images/E03-2.png" class="icon hvr-pop" />
                <div class="menu-name">精益</div>
              </div>
              <div class="icon-menu">
                <img src="../../assets/images/E03-3.png" class="icon hvr-pop" />
                <div class="menu-name">公平</div>
              </div>
              <div class="icon-menu">
                <img src="../../assets/images/E03-4.png" class="icon hvr-pop" />
                <div class="menu-name">共享</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottpm-contant ">
        <div class="company  ">
          <div class="left ">
            <div class="title">上海信睿</div>
            <div class="line"></div>
            <div class="mes">
              <div>2007年,公司成立"上海信睿信息技术有限公司"。</div>
              <div>公司创立之初,作为金蝶软件合作伙伴供应商,</div>
              <div>我们是利用金蝶业务和技术实现公司盈利及团队发展,</div>
              <div>同时给他们提供专业,快速的支持</div>
            </div>
          </div>
        </div>
        <img src="../../assets/images/E02.png" class="img"/>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name:"CompanyInformation"
    }
</script>

<style scoped lang="scss">
  .page{
    width: 100%;
    height: 100%;
    background-color: #eff3fb;
    text-align: center;
    padding: 30px 0;
    .top{
      margin-bottom: 40px;
      .title{
        color: #4f85e1;
        font-size: 18px;
      }
      .eng{
        letter-spacing: 5px;
        color:#d1d4d9;
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .content{
      width: 70%;
      margin-left: 15%;
      display: flex;
      .bottpm-contant{
        width: 50%;
        .img{
          display: block;
          height: 300px;
          width: -webkit-fill-available;
        }
        .company{
          height: 300px;
          background-color: #fff;
          .left{
            padding: 40px;
          }
          .title{
            text-align: left;
            color: #4f85e1;
            font-weight: 500;
          }
          .line{
            height: 3px;
            width: 40px;
            background-color: #dbdbdc;
            margin-top: 20px;
          }
          .mes{
            color: #555555;
            font-size: 12px;
            text-align: left;
            line-height: 20px;
            padding: 20px 0;
            span{
              color: #4f85e1;
            }
          }
          .icon-mes{
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            .icon-menu{
              .icon{
                width: 38px;
                height: 40px;
              }
              .menu-name{
                font-size: 13px;
                color: #636464;
                padding-top: 10px;
              }
            }
          }
        }
        .company:hover{
          background-color: #97b8f0;
          .title{
            color: #fff;
          }
          .mes{
            color: #fff;
            span{
              color: #fff;
            }
          }
          .icon-mes{
            .menu-name{
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
