<template>
  <div class="box">
    <div class="main">
      <p class="title">价值定位</p>
      <p class="title2">
        <span> ·主数据系统· </span>
    
      </p>
      <div class="main-text">
        <p>统一信息代码，实现各系统之间的数据一致性;</p>
        <p>
          统一数据管理,构建覆盖整个海信地产范围的数据管理基础,彻底消除信息不对称、“一物多码”等现象;
        </p>
        <p>提高企业的信息标准化管理水平、降低业务风险。</p>
      </div>
    </div>
    <div class="main">
      <p class="title">管理标准</p>
      <p class="title2">
        <span>·主数据系统·</span>
      </p>
      <div class="main-box">
        <div class="main-boxs wow animate__fadeIn">
          <div class="main-box-head">
            <img src="../../../assets/images/B08-7.png" class="hvr-pulse-grow" />
            <p>数据标准</p>
          </div>
          <div class="main-box-main">
            <p>定来源</p>
            <p>规定数据的唯一出处</p>
            <p>定结构</p>
            <p>规定主数据的属性，编码构成</p>
            <p>定规则</p>
            <p>约定数据之间的关联关系</p>
          </div>
        </div>
        <div class="main-boxs wow animate__fadeIn">
          <div class="main-box-head">
            <img src="../../../assets/images/B08-8.png" class="hvr-pulse-grow"/>
            <p>服务标准</p>
          </div>
          <div class="main-box-main">
            <p>定接口</p>
            <p>规范约定接口生产者、消费者、接口名称、参数</p>
            <p>定数据格式</p>
            <p>规定数据标准格式</p>
          </div>
        </div>
        <div class="main-boxs wow animate__fadeIn">
          <div class="main-box-head">
            <img src="../../../assets/images/B08-9.png" class="hvr-pulse-grow" />
            <p>管理标准</p>
          </div>
          <div class="main-box-main">
            <p>定流程</p>
            <p>规范项目、地块的录入、修改流程</p>
            <p>定职责</p>
            <p>定义数据管理者、流程参与者的职责</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main ">
      <p class="title">管理准备</p>
      <p class="title2">
        <span>·主数据系统·</span>
      </p>
      <div class="main-footer hvr-glow wow animate__fadeInUp">
        <div class="footer-head">
          <div class="footer-head-box">
            <div class="footer-icon-a">
              <i class="el-icon-star-on hvr-pop"></i>
            </div>
            <div class="footer-text">
              项目角度，以<span>项目、分期</span>为中心，全过程管理项目主数据全生命周期，实现从地块到项目的<span>闭环</span>处理
            </div>
          </div>
          <div class="footer-head-box">
            <div class="footer-icon-a">
              <i class="el-icon-star-on hvr-pop"></i>
            </div>
            <div class="footer-text">
              制定数据规范、统一数据标准，<span>整合</span>多个业务系统中最核心的、需要共享并保持-致的数据(主数据),解决<span>“数据孤岛”</span>问题，实现重点<span
                >管头(统一数据管理)</span
              >
            </div>
          </div>
          <div class="footer-head-box">
            <div class="footer-icon-a">
              <i class="el-icon-star-on hvr-pop" ></i>
            </div>
            <div class="footer-text">
              把统一、完整、准确的主数据分发给海信地产范围内需要使用这些数据的业务系统，实现重点管尾<span
                >(统一数据分发)</span
              >
            </div>
          </div>
        </div>
        <div class="footer-main">
          <p>价值定位</p>
          <p>统一信息代码,实现各系统之间的数据一致性:</p>
          <p>
            统一数据管理，构建覆盖整个海信地产范围的数据管理基础，彻底消除信息不对称、“一物多码”等现象;
          </p>
          <p>提高企业的信息标准化管理水平、降低业务风险</p>
        </div>
        <div class="footer-foot wow animate__fadeInUp">
          <img src="../../../assets/images/E005.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataSystem",
   data() {
    return {
     
    }
  },
  mounted(){
   this.$wow.init();
  },
};
</script>

<style scoped>
.animated {
  animation-duration: 1200ms    
}
.click {
  cursor: pointer;
}
.box {
  width: 100%;
  height: 100%;
}

.main {
  width: 70%;
  height: 100%;
  margin: 5% 0 0 15%;
}
.title {
  color: #4f85e1;
  font-size: 18px;
  text-align: center;
}
.title2 {
  color: #9b9da1;
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
  padding-bottom: 5%;
  letter-spacing: 5px;
}
.main-text {
  text-align: center;
  line-height: 1.5;
  color: #444444;
  font-size: 12px;
}
.main-box {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.main-boxs {
  width: 30%;
  text-align: center;
}
.main-box-head img {
  width: 35%;
}
.main-box-head p {
  font-size: 14px;
  color: #444444;
  line-height: 3;
}
.main-box-main {
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
  margin-top: 3%;
}
.main-box-main p:nth-child(odd) {
  color: #9b9da1;
}
.main-footer {
  width: 100%;
  background-color: #fff;
  border-radius: 1%;
}
.footer-head {
  width: 78%;
  padding-top: 2%;
  margin-left: 10%;
}
.footer-head-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3%;
}
.footer-icon-a {
  font-size: 2em;
  color: #2165d9;
  display: inline-block;
  margin-right: 1.2%;
  margin-top: -1%;
  /* float: left; */
}
/* .footer-icon-b {
  font-size: 2em;
  color: #2165d9;
  display: inline-block;
  margin-right: 1%;
  margin-top: 1%;
} */

.footer-text {
  font-size: 14px;
  line-height: 1.5;
}
.footer-text span {
  color: #2165d9;
}
.footer-main {
  width: 78%;
  padding-top: 5%;
  margin-left: 10%;
}
.footer-main p:first-child {
  color: #2165d9;
  font-size: 18px;
  margin-bottom: 2%;
}
.footer-main p:nth-child(n + 2) {
  line-height: 1.5;
  font-size: 14px;
}
.footer-foot {
  width: 83%;
}
.footer-foot img {
  width: 100%;
  padding: 5% 0 5% 10%;
}
.foot-right-box {
  width: 50%;
  display: flex;
}
</style>