<template>
  <div>
    <TheHeader class="head"></TheHeader>
    <CompanyInformation class="information"></CompanyInformation>
    <LightConsult></LightConsult>
    <DevelopMentHistory></DevelopMentHistory>
    <ContactUs></ContactUs>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
    import TheFooter from '@/components/footer/TheFooter.vue'
    import CompanyInformation from '@/components/About/CompanyInformation.vue'
    import LightConsult from '@/components/About/LightConsult.vue'
    import DevelopMentHistory from '@/components/About/DevelopMentHistory.vue'
    import ContactUs from '@/components/About/ContactUs.vue'
    export default {
        components: {
            TheFooter,
            CompanyInformation,
            LightConsult,
            DevelopMentHistory,
            ContactUs
        },
        name: "AboutXinRui"
    }
</script>

<style scoped>
  .head {
    position: fixed;
    background-color: #fff;
    z-index: 999;
  }
  .information{
    padding-top: 100px;
  }
</style>
