import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import ElementUI from 'element-ui';
import './assets/style/resrt.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import 'hover.css'
import {WOW} from 'wowjs'
import TheHeader from './components/header/TheHeader.vue'
import TheFooter from './components/footer/TheFooter.vue'
import BenchmarkCustomersHead from './components/BenchmarkCustomersHead/BenchmarkCustomersHead.vue'
import BenchmarkCustomersFoot from './components/BenchmarkCustomersFoot/BenchmarkCustomersFoot.vue'

import VueAnimateNumber from 'vue-animate-number'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAnimateNumber)
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 150, // default
  mobile: true, // default
  live: true, // default
  callback: function() {
    // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
}),
router.afterEach(() => {
  window.scrollTo(0,0);
});
Vue.component('TheHeader', TheHeader,'TheFooter',TheFooter,BenchmarkCustomersHead,'BenchmarkCustomersHead',BenchmarkCustomersFoot,'BenchmarkCustomersFoot')
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
