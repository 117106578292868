<template>
  <div class="box">
    <BenchmarkCustomersHead></BenchmarkCustomersHead>
    <div class="button">
      <p class="icon"></p>
      <span @click="hisense()" class="click">海信地产</span>
<!--      <span @click="zhongliang()" class="click">中梁地产</span>-->
    </div>
    <div class="news">
      <img src="../../assets/images/B01.png" class="hvr-float" >
      <div class="text-box hvr-float">
        <div class="text ">
          <p class="text-head">海信地产</p>
          <p class="text-main">
            青岛海信房地产股份有限公司 (简称“海信地产”)
            成立于1995年，拥有国家最高等级的房地产开发资质、
            国家最高等级的物业服务资质、国家最高等级的建筑设计资质，
            是山东省综合实力最强的房地产开发企业之一。先后荣获中国房地产品牌企业、
            中国房地产百强开发企业、中国值得尊敬的房地产品牌企业、中国城市运营商等荣誉称号，
            所开发项目多次斩获广厦奖、詹天佑奖、鲁班奖等国家最高建筑质量奖
          </p>
          <p class="click" @click="more()">查看更多</p>
        </div>
      </div>
    </div>
    <BenchmarkCustomersFoot></BenchmarkCustomersFoot>
  </div>
</template>

<script >
import BenchmarkCustomersHead from "@/components/BenchmarkCustomersHead/BenchmarkCustomersHead.vue";
import BenchmarkCustomersFoot from "@/components/BenchmarkCustomersFoot/BenchmarkCustomersFoot.vue";
export default {
  components: { BenchmarkCustomersHead, BenchmarkCustomersFoot },
  name: "TheHisense",
 
  methods: {
    hisense() {
      this.$router.push("/benchmarkcustomers/thehisense");
    },
    // zhongliang() {
    //   this.$router.push("/benchmarkcustomers/zhongliang");
    // },
    more() {
      this.$router.push("/benchmarkcustomers/thehisense/hisensemore");
    },
  },
};
</script>

<style lang="scss" scoped>
.click {
  cursor: pointer;
}
.button {
  width: auto;
  height: 50px;
  margin-left: 15%;
  text-align: center;
  font-size: 10px;
  padding: 5% 0 3% 0;
  .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 1%;
    background-color: #2165d9;
  }
  span {
    margin-right: 10%;
    font-size: 14px;
  }
}
.news {
  width: 70%;
  display: flex;
  margin-left: 15%;
  img {
    width: 45%;
    height: 45%;
  }
  .text-box {
    width: 55%;
    background-color: #fff;
    margin: 5% 0;
    .text {
      margin: 8% 8% 16%;
      .text-head {
        font-size: 24px;
      }
      .text-main {
        font-size: 12px;
        color: #9d9e9d;
        line-height: 1.5;
        padding: 5% 0;
        text-indent: 26px;
      }
      .click {
        color: #2165d9;
        text-align: right;
      }
    }
  }
}

</style>
