<template>
  <div class="content">
     <div class="box">
       <div :class="[{ 'item': item.active, 'items': !item.active }]" v-for="(item,index) in data"
            :key="index" @click="handSelect(item)">
         <img src="@/assets/images/六边形.png" alt="">
         <p class="item-year">{{ item.title }}</p>
         <div class="item-bottom" v-show="item.active">
           <p>{{ item.p1 }}</p>
           <p>{{ item.p2 }}</p>
           <p>{{ item.p3 }}</p>
         </div>
       </div>
     </div>
    </div>
</template>

<script>
export default {
  name: "YearCard",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    handSelect: function (e) {
      this.data.forEach(item => {
        item.active = (item === e) ? '1' : '';
      });
    }
  }
};

</script>
<style lang="scss" scoped>
  .content {
    width: 100%;
    margin: 0 auto;
    background-size: 100% !important;
    background: url("@/assets/images/B17.png") no-repeat center;

    .box{
      display: flex;
      margin: 0 auto;
      justify-content: space-evenly;

      .item {
        color: #fff;
        position: relative;
        cursor: pointer;
        transition: all 0.8s;
        img {
          width: 200px;
        }

        .item-year {
          font-size: 34px;
          font-weight: 600;
          position: absolute;
          left: 50%;
          top: 25%;
          transform: translate(-50%, -50%);
        }

        .item-bottom {
          width: 200px;
          display: flex;
          align-items: center;
          flex-direction: column;
          position: absolute;
          left: 50%;
          top: 55%;
          transform: translate(-50%, -50%);
          font-size: 12px;

          p {
            margin-bottom: 10px;
          }
        }
      }

      .items {
        position: relative;
        transform: translateY(35%);
        cursor: pointer;
        transition: all 0.8s;

        img {
          width: 60px;

        }

        p {
          color: #fff;
          font-size: 14px;
          position: absolute;
          top: 5%;
          left: 50%;
          transform: translate(-50%, 100%);
        }
      ;
      }
    }

  }
</style>