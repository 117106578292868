<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

    export default {
        name: 'App',
    }
</script>

<style>
  #app{
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
     min-width: 1200px;
  }
</style>
