<template>
  <div class="box">
    <div class="text ">
      <p class="text-head ">据不完全统计</p>
      <p class="text-main  ">信睿已成功完成<span>
        <animate-number
      from="1" 
      to="500" 
      duration="1000" 
    ></animate-number>

        </span>余项目</p>
      <div class="foot-box">
        <p class="text-foot">
          丰富的实战经验，让信睿在应对纷繁复杂的业务场景时游刃有余
        </p>
        <p class="text-foot">已更广泛的经验，为客户带来专业的体验</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BenchmarkCustomersHead",
  
};
</script>
<style lang="scss" scoped>
.box {
  width: 70%;
  height: 100%;
  margin-left: 15%;
  padding-top: 5%;
  .text {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/B世界地图.png") no-repeat;
    background-size: 60%;
    background-position: 50% 60%;
    text-align: center;
    .text-head {
      font-size: 24px;
      padding-top: 8%;
    }
    .text-main {
      font-size: 50px;
      padding-top: 2.5%;
      letter-spacing: 2px;
      span {
        color: #2165d9;
      }
    }
    .foot-box {
      margin-top: 5%;
      padding: 0 0 10% 0;
      .text-foot {
        font-size: 12px;
        margin-top: 1%;
      }
    }
  }
}
</style>

