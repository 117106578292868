<template>
  <div class="box">
       <div class="main">
      <CaseMenu></CaseMenu>
    </div>
  </div>
</template>

<script>
import CaseMenu from"@/components/CaseMenu/index.vue"
export default {
  components: { CaseMenu},
name:"PropertyPlatform",
methods: {
  assetplatform(){
     this.$router.push("/thesolution/assetplatform");
  },
  codeplatform(){
     this.$router.push("/thesolution/codeplatform");
  }
},
}
</script>

<style scoped>
.box{
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #eff3fb;
      padding-bottom: 5%;
}

</style>

