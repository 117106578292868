<template>
  <div class="box">

    <div class="main">
      <p class="title">基于战略运营实缴全盘货值顶层设计</p>
      <p class="title2">·货值系统·</p>
      <div class="main-box">
        <img src="../../../assets/images/C01.png" class="wow animate__fadeInUp" />
        <div class="main-text">
          <div class="text hvr-float wow animate__fadeIn">
            <div class="img">盘家底</div>
            <div class="text-box">
              <p>对货值资源做到心中有数，</p>
              <p>为制定经营目标提供支撑</p>
            </div>
          </div>
          <div class="text hvr-sink wow animate__fadeIn">
            <div class="img">控要素</div>
            <div class="text-box">
              <p>对核心三要素全周期跟踪,</p>
              <p>便于货值监控</p>
            </div>
          </div>
          <div class="text hvr-float wow animate__fadeIn">
            <div class="img">慧分析</div>
            <div class="text-box">
              <p>全过程价值链，对”投、产、</p>
              <p>供、销、回”平衡进行监控</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">盘家底</p>
      <p class="title2">·货值系统·</p>
      <div class="main-box">
        <div class="box hvr-glow wow animate__fadeInUp">
          <p class="box-title">
            对货值资源做到心中有数，为制定经营目标提供支撑
          </p>
          <img src="../../../assets/images/A00.png" alt="" />
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">控要素</p>
      <p class="title2">·货值系统·</p>
      <div class="main-box">
        <div class="box hvr-glow wow animate__fadeInUp">
          <p class="box-title">对核心三要素全周期跟踪，便于货值监控</p>
          <p class="red">货值=面积X单价”，同时随着时间动态变化</p>
          <p class="black">中后端货值的多少取决于合理价格最大化</p>
          <div class="box-foot">
            <div class="icon-box">
              <div class="foot-icon1  hvr-pop wow animate__flipInY">面积</div>
              <p>前端货值的多少取决于</p>
              <p>可售面积最大化</p>
            </div>
            <div class="icon-box">
              <div class="foot-icon2  hvr-pop wow animate__flipInY">单价</div>
            </div>
            <div class="icon-box">
              <div class="foot-icon3 hvr-pop wow animate__flipInY">时间</div>
              <p>全盘货值结构是否健康取决于</p>
              <p>投产供销回平衡</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">慧分析</p>
      <p class="title2">·货值系统·</p>
      <div class="main-box">
        <div class="box hvr-glow wow animate__fadeInUp">
          <p class="box-title">
            全过程价值链，对“投、产、供、销、回”平衡进行监控
          </p>
          <img src="../../../assets/images/C111.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ValueSystem",
    data() {
    return {
    }
  },
  mounted(){
        this.$wow.init();
  },

};
</script>

<style lang="scss" scoped>
.animated {
  animation-duration: 1200ms        //动画持续时间
}
.box {
  width: 100%;
  height: 100%;
  .main {
    width: 70%;
    height: 100%;
    margin: 5% 0 0 15%;

    .title {
      color: #2a6bdb;
      font-size: 18px;
      text-align: center;
    }
    .title2 {
      color: #9b9da1;
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
      padding-bottom: 5%;
      letter-spacing: 5px;
    }
    .main-box {
      width: 100%;
      height: 100%;
      margin-top: 2%;

      .box {
        width: 100%;
        background-color: #fff;
        border-radius: 0.3em;
        .box-title {
          color: #2165d9;
          text-align: center;
          padding: 5% 0;
          border-radius: 0.5em;
          font-size: 16px;
        }
        .red {
          color: red;
          text-align: center;
          font-size: 14px;
        }
        .black {
          color: black;
          font-weight: 800;
          text-align: center;
          margin: 3% 0;
          font-size: 14px;
        }
        .box-foot {
          display: flex;
          justify-content: space-between;
          width: 70%;
          margin-left: 15%;
          .icon-box {
            width: 30%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 10%;

            .foot-icon1 {
              width: 100%;
              padding: 25% 0;
              background: url("../../../assets/images/466.png") no-repeat;
              background-position: 50% 0;
              background-size: 50%;
              color: #fff;
              line-height: 50%;
              text-align: center;
              font-size: 1.5em;
            }
            .foot-icon2 {
              width: 100%;
              padding: 25% 0;
              background: url("../../../assets/images/455.png") no-repeat;
              background-position: 50% 0;
              background-size: 50%;
              color: #fff;
              line-height: 50%;
              text-align: center;
              font-size: 1.5em;
            }
            .foot-icon3 {
              width: 100%;
              padding: 25% 0;
              background: url("../../../assets/images/444.png") no-repeat;
              background-position: 50% 0;
              background-size: 50%;
              color: #fff;
              line-height: 50%;
              text-align: center;
              font-size: 1.5em;
            }
            p {
              font-size: 0.9em;
              padding-bottom: 5%;
            }
          }
        }
      }
      img {
        width: 100%;
      }
      .main-text {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        margin: 5% 0;
        .text {
          width: 30%;
          height: 50%;
          background-color: #fff;
          border-radius: 0.5em;
          // display: flex;
          .img {
            width: 100%;
            height: 100%;
            color: #2165d9;
            padding: 5% 0;
            text-align: center;
            background: url("../../../assets/images/B13-2.png") no-repeat;
            background-size: 15%;
            background-position: 50% 20%;
            margin: 5% 0 0 0;
          }
          .text-box {
            margin: 10% 0;
            text-align: center;

            p {
              font-size: 0.8em;
              line-height: 2;
            }
          }
        }
      }
    }
  }
}
</style>
