<template>
  <div class="box">
    <div class="page">
      <div class="left">
        <div class="logo">
          <img src="../../assets/images/LOGO2.png" @click="skipHome"/>
          <div class="logo-center"></div>
          <div class="logo-right">
            <p>上海信睿信息技术有限公司</p>
            <p>
              <span>担当</span>
              <span>·</span>
              <span>精益</span>
              <span>·</span>
              <span>公平</span>
              <span>·</span>
              <span>共享</span>
            </p>
          </div>
        </div>
      </div>
      <div class="right">
        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal">
          <el-menu-item v-for="(route,index) in constantRoutes" :key="route.path + index" :index="route.path"
                        @click="handleSelect(route)"
                        :class="isActive(route)?'active':''">
            {{ route.meta.title }}
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "TheHeader",
  data() {
    return {
      constantRoutes: []
    }
  },
  created() {
    this.getRoutes()
  },
  methods: {
    skipHome(){
      this.$router.push("/");
    },
    getRoutes() {
      const constant = router.options.routes
      this.constantRoutes = constant.filter((i => !i.meta.isHide))
    },
    handleSelect(route) {
      this.$router.push({name: route.name})
    },
    isActive(route) {
      const b = this.$route.path === '/'
      if (b) {
        return this.$route.path === route.path
      } else {
        if (route.path !== '/') {
          return this.$route.path.indexOf(route.path) != -1
        }
      }
    },
  },
}
;
</script>

<style scoped>

.box {
  width: 100%;
  min-width: 1200px;
}

.page {
  display: flex;
  margin-left: 15%;
  width: 70%;
  justify-content: space-between;
}

.left {
}

.logo {
  display: flex;
  margin-top: 5px;
}

.logo img {
  height: 40px;
  cursor: pointer;
}

.logo-center {
  height: 30px;
  width: 1px;
  background-color: #90b2ec;
  margin: 5px 5% 0 5%;
}

.logo-right {
  width: 200px;
  font-size: 12px;
}

.logo-right p {
  line-height: 20px;
}

.logo-right span {
  margin-right: 1%;
  color: #409EFF;
}

.right {
}

.item {
  width: 60%;
  height: 40px;
  margin-left: 15%;
}

.item span {
  margin-left: 10%;
  line-height: 40px;
  cursor: pointer;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0;
}

.el-menu-item {
  height: 50px;
  line-height: 50px;
}

.active {
  border-bottom: 2px solid #409EFF !important;
  color: #303133;
}

.el-menu {
  background: unset;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: unset;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: unset;
}
</style>
