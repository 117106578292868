<template>
  <div class="page">
    <div>
      <img :src="url" class="img"/>
      <div class="main-box">
        <div class="main-list">
          <div class="text-box" :style="{'color': isShow === 'property' ? '#4f85e1' : '#7d7e81'}" @click="tap('property')">
            <img src="../../assets/images/B08.png"  class="text-icon" v-if="isShow === 'property'"/>
            <img src="../../assets/images/B08-2.png"  class="text-icon" v-else />
            <div class="text">地产大运营平台</div>
          </div>
          <div class="text-box" :style="{'color': isShow === 'assets' ? '#4f85e1' : '#7d7e81'}" @click="tap('assets')">
            <img src="../../assets/images/B08-4.png"  class="text-icon" v-if="isShow === 'assets'"/>
            <img src="../../assets/images/B08-3.png"  class="text-icon" v-else />
            <span class="text">资产管理平台</span>
          </div>
          <div class="text-box" :style="{'color': isShow === 'code' ? '#4f85e1' : '#7d7e81'}" @click="tap('code')">
            <img src="../../assets/images/B08-6.png"  class="text-icon" v-if="isShow === 'code'"/>
            <img src="../../assets/images/B08-5.png"  class="text-icon" v-else />
            <span class="text">低代码平台</span>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
   // import router from "@/router";
    export default {
        name: "CaseHead",
        components: {},
        props: {},
        data() {
            return {
                isShow: 'property',
                url: require("../../assets/images/B09.png"),
                propertyUrl: require("../../assets/images/B09.png"),
                assetsUrl: require("../../assets/images/D02.png"),
                codeUrl: require("../../assets/images/D01.png"),
            }
        },
        created() {
        },
        watch:{
            $route: {
                handler:  function (val){
                    if(val.name === 'datasystem'){
                         this.isShow = 'property'
                         this.url = this.propertyUrl
                        this.$router.push("/thesolution/propertyplatform/datasystem");
                    }
                },
                // 深度观察监听
                deep:  true
            }
        },
        mounted() {
        },
        methods: {
            tap(data){
              this.isShow = data
                if(data === 'property'){
                    this.url = this.propertyUrl
                    this.$router.push("/thesolution/propertyplatform/datasystem");
                }else if(data === 'assets'){
                    this.url = this.assetsUrl
                    this.$router.push("/thesolution/assetplatform");
                }else if(data === 'code'){
                    this.url = this.codeUrl
                    this.$router.push("/thesolution/codeplatform");
                }
            }
        }
    }
</script>

<style scoped lang="scss">
  .page {
    height: 100%;
    width: 100%;
    background-color: #eff3fb;
    .img{
      width: 70%;
      height: 400px;
      margin: 50px auto 50px 15%;

    }
    .main-box{
      justify-content: center;
      display: flex;
      padding-bottom: 50px;
      .main-list{
        width: 500px;
        display: flex;
        justify-content: space-between;
      }
      .text-box{
        cursor: pointer;
        display: flex;
        .text-icon{
          height: 20px;
          width: 20px;
        }
        .text{
          font-size: 14px;
          margin-left: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>
