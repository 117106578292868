<template>
  <div class="box">
    <TheHeader class="head"></TheHeader>
     <div class="main">
      <router-view></router-view>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import TheFooter from "@/components/footer/TheFooter.vue";
export default {
  components: { TheFooter },
  name: "AboutXinRui",
  methods: {},
};
</script>

<style scoped>
.box {
  width: 100%;
}
.main {
  padding-top: 50px;
  width: 100%;
  background-color: #eff3fb;
}
 .head {
   position: fixed;
  background-color: #fff;
  z-index: 999;
}
</style>
