<template>
  <div class="page">
    <div class="top">
      <div class="title">轻咨询&快速开发稳定交付</div>
      <div class="eng">· 关于信睿 ·</div>
    </div>
    <div class="content wow animate__fadeInUp">
      <div class="top">
        <div class="left">
          <img src="../../assets/images/E06.png" class="img " />
        </div>
        <div class="left">
          <img src="../../assets/images/E09.png" class="img-09 "/>
        </div>
        <div class="left">
          <div>
            <img src="../../assets/images/LOGO2.png" class="img-logo " />
            <div>LDP微服务平台</div>
          </div>
        </div>
      </div>
      <div class="name-list">
        <div class="name-det">
          <div>战略级咨询导入</div>
        </div>
        <i class="el-icon-plus"></i>
        <div class="name-det">
          <div>行业最佳实践方案</div>
        </div>
        <i class="el-icon-plus"></i>
        <div class="name-det">自主平台柔性开发</div>
      </div>
      <div class="bottom-list">
        <div class="bottom">
          <img src="../../assets/images/E07.png" class="img" />
          <div class="bottom-text">管理模式建立与运营方法指导</div>
        </div>
        <div class="bottom">
          <div class="text ">
            <div>多家TOP级地产经验助力快速交付。</div>
            <div>信睿特有的交付方法论——<span>“总体规划、分步实施、重点突破、快速见效”</span></div>
            <div>支撑项目稳定交付。</div>
          </div>
          <div class="bottom-text">行业经验传递与实操方法的磨合</div>
        </div>
        <div class="bottom">
          <img src="../../assets/images/E08.png" class="img " />
          <div class="bottom-text">随需应变的柔性开发</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name:"LightConsult",
        mounted() {
           this.$wow.init();
        },
    }
</script>

<style scoped lang="scss">
img:hover{
  transform:scale(3.3);
	transition:all 0.5s ease;
}
  .page{
    width: 100%;
    height: 100%;
    background-color: #eff3fb;
    text-align: center;
    padding: 30px 0;
    .top {
      margin-bottom: 50px;

      .title {
        color: #4f85e1;
        font-size: 18px;
      }
      .eng {
        letter-spacing: 5px;
        color: #4a4d4d;
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .content{
      background-color: #fff;
      padding: 20px 0;
      width: 70%;
      margin-left: 15%;
      .left{
        width: 33%;
      }
      .top{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .img{
          height: 80px;
          width: 200px;
          // z-index: 23;
          }
        .img-09{
          height: 140px;
          width: 200px;
        }
        .img-logo{
          height: 35px;
          width: 100px;
          margin-bottom: 10px;
        }
      }
      .name-list{
        display: flex;
        justify-content: space-around;
        color: #2466D9;
        .name-det{
          width: 33%;
          display: flex;
          justify-content: space-around;
        }
        i{
          font-size: 18px;
        }
      }
    }
    .bottom-list{
      padding: 30px 10px 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .bottom{
        .img{
          height: 80px;
          width: 200px;
        }
        .text{
          height: 80px;
          width: 200px;
          font-size: 12px;
          text-align: left;
          line-height: 16px;
          span{
            color: #ff8018;
          }
        }
        .bottom-text{
          margin-top: 30px;
          color: #2466D9;
          font-size: 12px;
        }
      }
    }
  }
</style>
