<template>
  <div class="box">
    <div class="main">
      <div class="main-left">
        <div class="main-left-logo">
          <img src="../../assets/images/LOGO2.png" alt=""/>
          <div class="logo-center"></div>
          <div class="logo-right">
            <p>上海信睿信息技术有限公司</p>
            <p>
              <span>担当</span>
              <span>·</span>
              <span>精益</span>
              <span>·</span>
              <span>公平</span>
              <span>·</span>
              <span>共享</span>
            </p>
          </div>
        </div>
        <div class="main-left-d2">
          <div class="left-text">
            <span>·关于信睿</span>
            <span>·加入我们</span>
            <span>·法律声明及隐私政策 </span>
            <p>
              © Copyright SINRA China Co.,Ltd.All Rights Reserved.
              <a href="https://beian.miit.gov.cn/">备案号: 沪ICP备18016351号</a>
            </p>
          </div>
          <div class="right-text">
            <div><span>T</span>021-39885096<span style="margin: 0 5px">E</span>kangm@shxrtech.com</div>
            <div><span>A</span>上海市杨浦区昆明路518号北美广场A座905室</div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <img src="../../assets/images/B16.png" alt="">
        <p>扫一扫知信睿</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 30vh;
  background-color: #2e2e2e;
  overflow: hidden;

  .main {
    width: 70%;
    margin: 2.5% auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #C1C1C3;

    .main-left {
      width: 81%;

      .main-left-logo {
        width: 35%;
        height: 25%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 30%;
        }

        .logo-center {
          width: 2px;
          height: 90%;
          background-color: #C1C1C3;
          margin: 2% 5%;
          padding: 5% 0;
        }

        .logo-right {
          width: 60%;
          margin-top: 3%;

          p {
            font-size: 12px;
            margin-bottom: 3%;
            color: #C1C1C3;

            span {
              margin-right: 1%;
            }
          }
        }
      }

      .main-left-d2 {
        width: 100%;
        height: 25%;
        margin-top: 4%;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        align-items: flex-end;

        .left-text {
          width: 60%;
          span {
            margin-right: 5%;
          }

          p {
            margin-top: 3%;
            color: #C1C1C3;

            a {
              color: #C1C1C3;
            }
          }

          position: relative;

          &::after {
            content: '';
            width: 2px;
            height: 40px;
            background-color: #c1c1c3;
            position: absolute;
            top: 0;
            left: 100%;
          }
        }

        .right-text {
          width: 35%;
          div:first-child {
            margin-bottom: 5%;

          }
          span {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 3px;
            background-color: #3371DC;
            border-radius: 50%;
            text-align: center;
          }
        }
      }
    }

    .main-right {
      width: 10%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        padding-bottom: 10px;
      }
    }
  }
}

</style>
