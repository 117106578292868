<template>
  <div class="box">
    <div class="main ">
      <p class="title">功能架构总览</p>
      <p class="title2">·成本系统·</p>
      <div class="main-box hvr-glow wow animate__fadeInUp">
        <p class="wow animate__fadeInUp">管理前置、过程精细、内外协同、数据赋能</p>
        <img src="../../../assets/images/612.png" class="wow animate__fadeInUp" />
      </div>
    </div>
    <div class="main">
      <p class="title">纵向横向一体化</p>
      <p class="title2">·成本系统·</p>
      <div class="main-box hvr-glow animate__fadeInUp">
        <img src="../../../assets/images/613.png" class="wow animate__fadeInUp"/>
      </div>
    </div>
    <div class="main">
      <p class="title">合同执行全过程管理</p>
      <p class="title2">·成本系统·</p>
      <div class="main-box hvr-glow wow animate__fadeInUp">
        <p class="wow animate__fadeInUp">
          统一合同管理，实现合同生命周期管理，相关金额实时拆分，为动态成本归集提供及时有效的业务数据。
        </p>
        <img src="../../../assets/images/B141.png" class="wow animate__fadeInUp"/>
      </div>
    </div>
    <div class="main">
      <p class="title">动态成本管理</p>
      <p class="title2">·成本系统·</p>
      <div class="main-box hvr-glow wow animate__fadeInUp">
        <p>
          规范成本核算、分摊规则，实时监控成本动态变化，准确的动态成本管理体系
        </p>
        <img src="../../../assets/images/B151.png" class="wow animate__fadeInUp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "CostSystem",
  data() {
    return {
     
    }
  },
  mounted() {
        this.$wow.init();
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.animated {
  animation-duration: 1200ms        //动画持续时间
}
.click{
  cursor:pointer;
}
.box {
  width: 100%;
  height: 100%;
  .head {
    width: 70%;
    margin-left: 15%;
    color: #444444;
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    span {
      width: 0.5em;
      height: 0.5em;
      background-color: #2165d9;
      display: inline-block;
      border-radius: 50%;
      margin-right: 0.3em;
    }
  }
  .main {
    width: 70%;
    height: 100%;
    margin-top: 5%;
    margin-left: 15%;
    .title {
      color: #2a6bdb;
      font-size: 18px;
      text-align: center;
      letter-spacing: 5px;
    }
    .title2 {
      color: #9b9da1;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 5%;
    letter-spacing: 5px;
    }
    .main-box {
      background-color: #fff;
      border-radius: 0.3em;
      p {
        text-align: center;
        font-size: 14px;
        color: #2165d9;
        padding-top: 5%;
      }
      img {
        width: 90%;
        padding: 5% 0;
        margin-left: 5%;
      }
    }
  }
}
</style>

