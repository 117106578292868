<template>
  <div class="under  wow animate__fadeInUp">
    <img src="../../assets/images/B02.png" alt="" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$wow.init();
  },
};
</script>

<style scoped>
.under {
  width: 70%;
  height: 400px;
  padding: 5% 0;
  margin-left: 15%;
}
.under img {
  width: 100%;
  height: 100%;
}
</style>