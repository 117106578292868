<template>
  <div class="box">
    <TheHead class="head"></TheHead>
    <div id="box">
      <div class="main" style="padding-top:8%">
        <img src="../../../assets/images/B05.png" class="picture"/>
      </div>
      <div class="main ">
        <p class="title">建设过程</p>
        <p class="title2">·海信地产·</p>
        <YearCard :data="arr"></YearCard>
      </div>
      <div class="main">
        <p class="title">中控平台、移动应用项目效益分析</p>
        <p class="title2">·海信地产·</p>
        <div class="platform">
          <div class="picture2">
            <img src="../../../assets/images/B04.png" alt=""/>
          </div>
          <div class="news-box ">
            <div class="news">
              <p>统一数据集成</p>
              <p>
                作为集成平台，通过统一待办信息集成，快速建立员工与审批流的信息通道，缩短流程获取时间，提升信息触达率
              </p>
            </div>
            <div class="news">
              <p>构建系统生态</p>
              <p>
                整合多业务系统，通过统一的系统入口实现与第三方业务系统对接，打通业务连接，构建系统生态
              </p>
            </div>
            <div class="news">
              <p>提高工作效率</p>
              <p>
                通过移动终端可实现多种场景下及时办理办公信息，打通企业内部信息交流壁垒，实现信息的快速触达及双向反馈
              </p>
            </div>
            <div class="news">
              <p>节省时间成本</p>
              <p>
                突破传统pc端的办公局限，移动化办公通过“碎片化”时间处理业务节省了大量的非必要工作时间
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="main ">
        <p class="title">招采项目效益分析</p>
        <p class="title2">·海信地产·</p>
        <div class="main-box hvr-glow  wow animate__fadeInUp">
          <div class="main-box-title">提效分析</div>
          <div>
            <p class="icon"></p>
            同品类合格供方在系统一并展示，便于招采经办人选择入围单位投标，提效5.7%,每月节约16人天(采购部负责招标共13人，预计每月发标10次，搜集供方信息约1小时，13*10*1/8=16人天)
          </div>
          <div>
            <p class="icon"></p>
            招标过程在线与供方协同，自动抓取抵房比例、电票上浮率等相关信息，减少人为失误，提效约3%，每月节约8人天(预计每月发标10次流转定标信息、计算定标金额约0.5小时，13*10*0.5/88人天)
          </div>
          <div>
            <p class="icon"></p>
            招采经办人设置招标时间，系统展示是否回标，实现自动开标，提效约17%，每月节约49人天(预计每月发标10次，确定供方是否回标及监察室开标约3小时，13*10*3/8~49人天)
          </div>
          <div>
            <p class="icon"></p>
            降低成本，通过不同的招标方式，规范管理模式，降低企业管理成本;开放供方报名通道，加大供方引入数量，加大竞争力度，降低采购成本
          </div>
          <div>
            <p class="icon"></p>
            沉淀和管理数据，通过招采系统上线，沉淀供方数据及招采数据，为后续采购定标价格分析、定标决策分析、供方引入分析、全景计划完成率分析奠定重要基础
          </div>
        </div>
      </div>
      <div class="main">
        <p class="title">计划系统项目效益分析</p>
        <p class="title2">·海信地产·</p>
        <div class="main-box hvr-glow  wow animate__fadeInUp">
          <div class="main-box-head">
            <span>标准建立：</span>
            梳理业务标准化文件，建立计划模板，定制标准节点、标准工期、标准成果等，协助海信地产搭建计划运营管理体系。
          </div>
          <div>
            <span>计划落地：</span>
            实现计划分级管理、点部。子公司/项目总、项目部对应各级主项计划，同时增加各职能专项计划他工程批次计划的落实到位。
          </div>
          <div>
            <span>过程预警：</span>
            计划与运营联动，及时发现实际运营与计划目标的差异,提高项目风险识别及预控能力。
          </div>
          <div>
            <span>运营分析：</span>
            通过实时报表，数据分析，优化运营管理标准，上下级参与审核，准确高效。
          </div>
          <div>
            <span>业务交圈：</span>
            计划节点作为项目协同的主线，实现计划与海信其他业务系统模块的业务协同、数据共享;梳理完善公司项目讨程专题会议体系实现会议管理，确保会议按期召开，会议决议及布置工作可以及时记录归档。
          </div>
        </div>
      </div>
      <div class="main">
        <p class="title">客户中台项目效益分析</p>
        <p class="title2">·海信地产·</p>
        <div class="main-box hvr-glow  wow animate__fadeInUp">
          <div class="main-box-head">
            <span>强后台：</span>
            当前已建设的内部业务管理系统: 销售、主数据、海信家、海信地产公众号;
          </div>
          <div>
            <span>大中台：</span>
            客户中台一期建设的有客户中心，房源中心，交易中心，标签管理，支撑此次海信地产小程序前端应用的实现，
          </div>
          <div>
            <span>小前台：</span>
            此次包含了电子单据、电子协议，交款通知、催缴款通知，业务认证、新间资讯、交房通知、客户关怀等，客户中台未来还将创建交付中心，会员中心，工单中心，服务中心
          </div>
          <div>
            <span>核心价值：</span>
            将系统核心复用东西规范化、组件化处理，即使公司发生业务改变，核心业务也能快速搭建完成，本职在于增效降本《增效:系统快速复用、能力不断沉淀，降本:降低重复建设)
          </div>
          <div>
            <span>核心意义：</span>
            快速复用与各个业务场景进行多重组合拆解，与业务直接相关联的前台更新迭代与创新拓展，实现快速试错。
          </div>
          <div>
            <span>中台模块拆分原则：</span>
            明确中台的核心模块，逐个模块进行拆解，明确参与角色，通过角色-用例关系进行拆解一级模块，明确场景、权限，细化
            至2、3、4级模块
          </div>
        </div>
      </div>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import TheHead from "@/components/header/TheHeader.vue";
import TheFooter from "@/components/footer/TheFooter.vue";
import YearCard from "@/pages/BenchmarkCustomers/components/YearCard";

export default {
  components: {YearCard, TheHead, TheFooter},
  name: "HisenseMore",
  data() {
    return {
      arr: [
        {
          title: '2019',
          p1: '主数据平台、招采平台',
          p2: 'K2-BPM流程中心',
          p3: '中控平台、海信地产移动应用',
          active: '1'
        },
        {
          title: '2020',
          p1: '供应商协同平台',
          p2: '计划管理系统',
          p3: '招采平台二期',
          active: ''

        },
        {
          title: '2021',
          p1: '客户中台',
          p2: '中控平台二期',
          p3: '供应商协同平台二期',
          active: ''

        },
        {
          title: '2022',
          p1: '物业综合管理平台',
          p2: '财务共享对接',
          p3: '',
          active: ''
        },
      ]
    }
  },
  mounted() {
    this.$wow.init();
  },
};
</script>

<style scoped>
.animated {
  /* 动画持续时间 */
  animation-duration: 1200ms
}

.box {
  width: 100%;
}

#box {
  background-color: #eff3fb;
}

.title {
  color: #2a6bdb;
  font-size: 18px;
  text-align: center;
}

.title2 {
  color: #9b9da1;
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
  padding-bottom: 5%;
  letter-spacing: 5px;
}

.main {
  width: 70%;
  margin-left: 15%;
  padding-bottom: 5%;
}

.main .picture {
  width: 100%;
}

.platform {
  display: flex;
  align-items: center;
  position: relative;
}

.main .picture2 {
  width: 40%;
  margin-left: 10%;
}

.picture2 img {
  width: 100%;
  display: inline-block;
}

.background {

  background-size: 100%;
  background: url("../../../assets/images/B17.png") no-repeat center;

}

.main-box {
  width: 100%;
  background-color: #fff;
  border-radius: 0.3em;
  color: #4f4f4f;

  margin-top: 2.5%;
}

.main-box-head {
  padding-top: 5% !important;
}

.main-box div {
  line-height: 1.5;
  margin: 0 6%;
  padding: 1.5% 0;
}

.news-box {
  left: 46% !important;
  width: 60%;
}

.news-box div:first-child {
  left: -12%;
  width: 84%;
}

.news-box div:nth-child(2) {
  left: -5%;
  width: 84%;
}

.news-box div:nth-child(3) {
  width: 84%;
}

.news-box div:last-child {
  left: -3%;
  width: 84%;
}

.news {
  width: 65%;
  position: relative;
}

.news p:first-child {
  font-size: 0.3em;
  margin-top: 7%;
  font-weight: 600;
}

.news p:last-child {
  font-size: 0.2em;
  margin-top: 2%;
  line-height: 1.5;
}

.icon {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  background-color: #2165d9;
  border-radius: 50%;
  margin-right: 0.5%;
}

.main-box-title {
  padding-top: 5% !important;
  color: #2165d9;
  font-size: 1em !important;
}

.main-box div:nth-child(n + 1) {
  font-size: 0.8em;
}

.main-box div:last-child {
  padding-bottom: 5%;
}

.main-box span {
  color: #2165d9;
}

.head {
  position: fixed;
  background-color: #fff;
  z-index: 999;
}
</style>
