<template>
  <div class="box">
    <TheHeader class="head"></TheHeader>
    <CaseHead class="case-head"></CaseHead>
   
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import TheFooter from "@/components/footer/TheFooter.vue";
import CaseHead from "@/components/CaseHead/index.vue";

export default {
  components: { TheFooter,CaseHead },
  name: "TheSolution",
};
</script>

<style scoped>
.box {
  width: 100%;
}
.head {
  position: fixed;
  background-color: #fff;
  z-index: 999;
}
.case-head{
  padding-top: 50px;
}
</style>

