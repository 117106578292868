import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import HomePage from '../pages/HomePage.vue'
// 标杆客户
import BenchmarkCustomers from '../pages/BenchmarkCustomers.vue'
// 解决方案
import TheSolution from '../pages/TheSolution'
// 关于信睿
import AboutXinRui from '../pages/AboutXinRui'


// 海信地产首页
import TheHisense from '../pages/BenchmarkCustomers/TheHisense.vue'
// 中梁地产首页
// import ZhongLiang from '../pages/BenchmarkCustomers/ZhongLiang.vue'


// 地产大运营平台
import PropertyPlatform from '../pages/TheSolution/PropertyPlatform/PropertyPlatform.vue'
// 资产管理平台
import AssetPlatform from '../pages/TheSolution/AssetPlatform/AssetPlatform.vue'
// 低代码平台
import CodePlatform from '../pages/TheSolution/CodePlatform/CodePlatform.vue'


// 主数据系统
import DataSystem from '../pages/TheSolution/PropertyPlatform/DataSystem.vue'
// 计划系统
import PlanningSystem from '../pages/TheSolution/PropertyPlatform/PlanningSystem.vue'
// 货值系统
import ValueSystem from '../pages/TheSolution/PropertyPlatform/ValueSystem.vue'
// 招采系统
import ProcurementSystem from '../pages/TheSolution/PropertyPlatform/ProcurementSystem.vue'
// 成本系统
import CostSystem from '../pages/TheSolution/PropertyPlatform/CostSystem.vue'


// 海信地产更多信息
import HisenseMore from '../pages/BenchmarkCustomers/TheHisense/HisenseMore.vue'

// 中梁地产更多信息
// import ZhongLiangMore from '../pages/BenchmarkCustomers/ZhongLiang/ZhongLiangMore.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

export const constantRoutes =  [
    {
        path: '/',
        component: HomePage,
        name: 'homepage',
        meta: {
            title: '首页',
            isHide: false,
            isPopover: true
        }
    },
    {
        path: "/benchmarkcustomers",    //标杆客户
        name: "benchmarkcustomers",
        component: BenchmarkCustomers,
        redirect: '/benchmarkcustomers/thehisense',
        meta: {
            title: '标杆客户',
            isHide: false,
            isPopover: true
        },
        children: [
            {
                path: "thehisense",         //海信地产
                name: 'thehisense',
                component: TheHisense,
            },
            // {
            //     path: "zhongliang",         //中粮地产
            //     name: "zhongliang",
            //     component: ZhongLiang
            // },
        ]
    },
    {
        path: "/thesolution",               //解决方案
        name: "thesolution",
        component: TheSolution,
        redirect: '/thesolution/propertyplatform/datasystem',
        meta: {
            title: '解决方案',
            isHide: false,
            isPopover: true
        },
        children: [
            {
                path:"propertyplatform",            //地产大运营平台
                name:'propertyplatform',
                component:PropertyPlatform,
                children:[
                    {
                        path:"datasystem",
                        name:'datasystem',          //主数据系统
                        component:DataSystem
                    },
                    {
                        path:"planningsystem",        //计划系统
                        name:'planningsystem',
                        component:PlanningSystem
                    },
                    {
                        path:"valuesystem",             //货值系统
                        name:'valuesystem',
                        component:ValueSystem
                    },
                    {
                        path:"procurementsystem",           //招采系统
                        name:'procurementsystem',
                        component:ProcurementSystem
                    },
                    {
                        path:"costsystem",                  //成本系统
                        name:'costsystem',
                        component:CostSystem
                    }
                ]
            },
            {
                path:"assetplatform",
                name:'assetplatform',
                component:AssetPlatform                 //资产管理平台
            },
            {
                path:"codeplatform",
                name:'codeplatform',
                component:CodePlatform                  //低代码平台
            }
        ]
    },
    {
        path: "/aboutxinrui",
        name: "aboutxinrui",
        component: AboutXinRui,                     //关于信睿
        meta: {
            title: '关于信睿',
            isHide: false,
            isPopover: true
        },
    },
    {
        path: "/benchmarkcustomers/thehisense/hisensemore",
        name: 'hisensemore',
        component: HisenseMore,                     //海信更多信息
        // redirect: '/hisensemore/thenineteen',
        meta: {
            title: '海信更多信息',
            isHide: true,
            isPopover: true
        },
    },
    // {
    //     path: "/benchmarkcustomers/zhongliang/zhongliangmore",
    //     name: 'zhongliangmore',
    //     component: ZhongLiangMore,                      //中粮地产更多信息
    //     meta: {
    //         title: '中粮地产更多信息',
    //         isHide: true,
    //         isPopover: true
    //     }
    // }
]

const router = new VueRouter({
    mode: 'hash',
    routes: constantRoutes
})
export default router
