<template>
  <div class="page ">
    <div class="top">
      <div class="title">发展历程</div>
      <div class="eng">DEVELOPMENT HISTORY</div>
    </div>
    <div class="content wow animate__fadeInUp">
      <i class="el-icon-arrow-left icon-left" @click="click('left')"></i>
      <div class="line">
        <div class="border"></div>
        <div class="border" style="left: 22%"></div>
        <div class="border-white">
          <div>
            <div class="year">{{showForm.year}}</div>
            <div class="data">{{showForm.data}}</div>
          </div>
        </div>
        <div class="border" style="left: 77%"></div>
        <div class="border" style="left: 90%"></div>
      </div>
      <i class="el-icon-arrow-right icon-right" @click="click('right')"></i>
    </div>
  </div>
</template>

<script>
    export default {
        name: "DevelopMentHistory",
        components: {},
        props: {},
        data() {
            return {
                list: [
                    {
                        year: '2009',
                        data: '南京分公司成立,率先帮助南京组织,华东有色等企业实现业财一体化，推动南京本地国资委企业信息化深入应用。'
                    },
                    {
                        year: '2012',
                        data: '公司全面向房地产行业转型借助旭辉集团先进管理理念开创货值精细化管控先河。'
                    },
                    {
                        year: '2014-2016',
                        data: '公司快速突破地产行业，陆续签约南京银城集团、上海大华集团、融创集团、南京港集团、正大广场、弘阳地产、新力地产集团。'
                    },
                   {
                        year: '2017',
                        data: '公司自主研发快速开发平台XRL DP1.0版'
                   },
                   {
                        year: '2018',
                        data: ' 深耕地产行业，构建大运营管理平台，快速突破千亿业绩。'
                   },
                   {
                        year: '2019',
                        data: '公司自主研发基于微服务架构快速开发平台XRLDP2.0版，助力青岛海信构建大运营管理平台，成功将标杆地产管理模式向区域地产复制。'
                   },
                  {
                        year: '2020-2022',
                        data: '与上海大数据实验室合作，共同研发地产数字化算法引擎。基于区块链技术，打造地产行业创新金融平台。'
                   },                                 
                ],
                showForm: {}
            }
        },
        computed: {},
        created() {
            this.showForm = this.list[0]
        },
        mounted() {
           this.$wow.init();
        },
        methods: {
            click(data){
                const ind = this.list.findIndex(i=>i.year === this.showForm.year)
                if(data === 'left'){
                    if(ind === 0) return
                    this.showForm = this.list[ind - 1]
                }else{
                    if(ind === this.list.length-1) return
                    this.showForm = this.list[ind + 1]
                }
            }
        }
    }
</script>

<style scoped lang="scss">
  .page {
    width: 100%;
    height: 100%;
    background-color: #eff3fb;
    text-align: center;
    padding: 30px 0;

    .top {
      margin-bottom: 20px;

      .title {
        color: #4f85e1;
        font-size: 18px;
      }

      .eng {
        letter-spacing: 5px;
        color: #d1d4d9;
        margin-top: 15px;
        font-size: 12px;
      }
    }

    .content {
      width: 70%;
      margin-left: 15%;
      height: 500px;
      align-items: center;
      display: flex;
      position: relative;
      .icon-left{
        position: absolute;
        font-size: 30px;
        left: 0;
        font-weight: 600;
        color: #2165d9;
        cursor: pointer;
      }
      .icon-right{
        position: absolute;
        font-size: 30px;
        right: 0;
        font-weight: 600;
        color: #2165d9;
        cursor: pointer;
      }
      .line {
        width: 100%;
        transform: rotateZ(335deg);
        height: 2px;
        background-color: #b8b9ba;
        margin: 0 auto;
        position: relative;
      }

      .border {
        height: 20px;
        width: 20px;
        background-color: #2165d9;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        left: 10%;
      }

      .border-white {
        height: 300px;
        width: 300px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: -150px;
        left: 34%;
        border: 4px solid #2165d9;
        transform: rotateZ(25deg);
        display: flex;
        align-items: center;
        justify-content: center;
        .year{
          font-size: 40px;
          font-weight: 600;
          color: #2165d9;
        }
        .data{
          width: 160px;
          font-size: 12px;
          line-height: 18px;
          margin: 20px auto 0 auto;
          color: #828283;
        }
      }
    }
  }
</style>
