<template>
  <div class="box">
    <div class="main">
      <p class="title">建设目标</p>
      <p class="title2">
        <span> ·计划系统· </span>
        
      </p>
      <div class="main-box">
        <div class="left">
          <img src="../../../assets/images/B12.png" class="img hvr-wobble-vertical wow animate__backInUp" />
        </div>
        <div class="right wow animate__fadeInRight">
          <div class="box">
            <div class="left">标准建立</div>
            <div class="right">
              梳理业务标准化文件,建立计划模版。定制标准节点标准工期、标准成果等
            </div>
          </div>
          <div class="box">
            <div class="left">计划落地</div>
            <div class="right">
              实现计划分级管理主项计划总部、子公司/项目总项目部对应一级、二级、三级计划的计划落实到位
            </div>
          </div>
          <div class="box">
            <div class="left">过程预警</div>
            <div class="right">
              计划与运营联动，及时发现实际运营与计划目标的差异。提高项目风险识别及预控能力。
            </div>
          </div>
          <div class="box">
            <div class="left">运营分析</div>
            <div class="right">
              通过实时报表，数据分析，优化运营管理标准，上下级参与审核，准确高效
            </div>
          </div>
          <div class="box">
            <div class="left">业务交圈</div>
            <div class="right">
              计划节点作为项目协同的主线，实现计划与海信其他业务系统模块的业务协同、数据共享。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">重大变革</p>
      <p class="title2">
        <span>·计划系统·  </span>
        
      </p>
      <div class="main-text">
        <div class="main-text-box hvr-glow wow animate__fadeInUp">
          <div>
            <img src="../../../assets/images/B13.png" class="hvr-push" />
          </div>
          <div class="text-box ">
            <div class="text-title">计划管理数字化(信息量化)</div>
            <div class="text-main">
              通过数字化计划编制与节点汇报保证数据真实有效
            </div>
            <div class="text-main">建立有效标准工期库，指导计划编制与执行</div>
            <div class="text-main">建立标准计划模板库，快速生成多级计划</div>
          </div>
        </div>
        <div class="main-text-box hvr-glow  wow animate__fadeInUp">
          <div>
            <img src="../../../assets/images/B14.png" class="hvr-push"  />
          </div>
          <div class="text-box ">
            <div class="text-title">计划运营一体化(业务交圈)</div>
            <div class="text-main">实现业务异常的发现和处理能力,</div>
            <div class="text-main">
              有效集成业务系统，形成主项计划与专项计划联动
            </div>
            <div class="text-main">建立有效的计划落地保障机制</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">提升点</p>
      <p class="title2">
        <span>·计划系统·  </span>
       
      </p>
      <div class="main-foot hvr-glow wow animate__fadeInUp">
        <div class="foot-box wow animate__fadeInUp">
          <div class="foot-icon hvr-push ">1</div>
          <div class="foot-title-box"> 
            <div class="foot-title">节点汇报的防伪:</div>
            <div class="foot-text">1.定义汇报成果条目</div>
            <div class="foot-text">
              2.汇报提供上传图片或附件来对结果进行具象化的描述
            </div>
            <div>3.汇报结果需要本部门及下一阶段相关部门参与确认</div>
          </div>
        </div>
        <div class="foot-box  wow animate__fadeInUp">
          <div class="foot-icon hvr-push">2</div>
          <div class="foot-title-box">
            <div class="foot-title">标准工期库与分析:</div>
            <div>1.定义第一-版标准工期</div>
            <div>2.提供报表对实际工期进行分析</div>
            <div>3.出具节点工期均值，用于工期优化</div>
          </div>
        </div>
        <div class="foot-box wow wow animate__fadeInUp">
          <div class="foot-icon hvr-push ">3</div>
          <div class="foot-title-box">
            <div class="foot-title">过程预警:</div>
            <div>1.关键节点/长周期节点做阶段性节点汇报</div>
            <div>2.阶段性汇报可以在过程中提前暴露风险</div>
          </div>
        </div>
        <div class="foot-box wow wow animate__fadeInUp">
          <div class="foot-icon hvr-push ">4</div>
          <div class="foot-title-box">
            <div class="foot-title">计划编制一键生成:</div>
            <div>1.设定计划模板来解决模块快速生成的问题</div>
          </div>
        </div>
        <div class="footer-box wow animate__fadeInUp">
          <div class="footer-icon hvr-push ">5</div>
          <div class="footer-title-box">
            <div class="footer-title">业务交圈:</div>
            <div>
              1.本次计划系统主要管理项目主计划，专项计划通过与业务系统J通来实现
            </div>
            <div>
              2.现阶段需要打通的有主数据系统(阶段于计划节点打通)、招采主项计划打通、成本的目标成本审核通过后与计划打通、会议预定系统与计划打通
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanningSystem",
  mounted(){
   this.$wow.init();
  },
};
</script>

<style lang="scss" scoped>
.animated {
  animation-duration: 1200ms        //动画持续时间
}
.box {
  width: 100%;
  height: 100%;
  .main {
    width: 70%;
    height: 100%;
    margin: 5% 0 0 15%;
    .title {
      color: #4f85e1;
      font-size: 18px;
      text-align: center;
    }
    .title2 {
      color: #9b9da1;
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
      padding-bottom: 5%;
      letter-spacing: 5px;
    }
    .main-box {
      width: 100%;
      height: 100%;
      display: flex;
      margin-top: 10%;
      .left {
        width: 55%;
        .img {
          width: 93%;
          height: 85%;
        }
      }
      .right {
        width: 45%;

        .box {
          width: 100%;
          height: 20%;
          border-bottom: 1px #9b9da1 solid;
          display: flex;
          align-items: center;
          .left {
            color: #2165d9;
            font-style: italic;
            width: 25%;
            font-size: 16px;
            margin-top: -3%;
          }
          .right {
            width: 75%;
            height: 100%;
            line-height: 1.5;
            font-size: 0.9em;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .main-text {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      img {
        width: 70%;
        margin: 120% 0 0 50%;
      }
      .main-text-box {
        width: 49%;
        background-color: #fff;
        display: flex;
        border-radius: 0.3em;
        .text-box {
          width: 90%;
          margin: 5% 5%;
          .text-title {
            color: #2a6bdb;
            font-size: 1em;
            padding-bottom: 3.5%;
          }
          .text-main {
            font-size: 0.8em;
            line-height: 1.5;
          }
        }
      }
    }
    .main-foot {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      border-radius: 0.3em;
      background-color: #fff;

      .foot-box {
        width: 50%;
        // display: flex;
        .foot-icon {
          width: 35px;
          height: 35px;
          background-color: #2a6bdb;
          color: #fff;
          font-size: 28px;
          border-radius: 50%;
          text-align: center;
          line-height: 35px;
          margin: 8% 0 0 10%;
          float: left;
        }
        .foot-title-box {
          width: 90%;
          margin: 5% 5%;
        }
        .foot-title-box div:nth-child(n + 2) {
          font-size: 0.8em;
          line-height: 1.5;
          margin-left: 20%;
        }
        .foot-title {
          color: #2a6bdb;
          font-size: 14px;
          padding-bottom: 5%;
          margin-left: 20%;
        }
      }
      .footer-box {
        width: 100%;
        .footer-icon {
          width: 35px;
          height: 35px;
          background-color: #2a6bdb;
          color: #fff;
          font-size: 28px;
          border-radius: 50%;
          text-align: center;
          line-height: 35px;
          margin: 5% 0 0 5%;
          float: left;
        }
        .footer-title-box {
          width: 90%;
          margin: 2% 5% 5% 5%;
        }
        .footer-title-box div:nth-child(n + 2) {
          font-size: 0.8em;
          line-height: 1.5;
          margin-left: 7.5%;
        }
        .footer-title {
          color: #2a6bdb;
          font-size: 14px;
          padding-bottom: 3%;
          margin-left: 7.5%;
        }
      }
    }
    // .footer-box {
    //   width: 100%;
    //   background-color: #fff;
    //   display: flex;
    //   border-bottom-right-radius: 0.3em;
    //   border-bottom-left-radius: 0.3em;
    //   .footer-icon {
    //     width: 1.5em;
    //     height: 1.4em;
    //     background-color: #2a6bdb;
    //     color: #fff;
    //     font-size: 1.4em;
    //     border-radius: 50%;
    //     text-align: center;
    //     line-height: 1.3em;
    //     margin: 8% 0 0 5.5%;
    //   }
    //   .footer-title-box {
    //     width: 90%;
    //     margin: 5% 5% 5% 2%;
    //   }
    //   .footer-title-box div:nth-child(n + 2) {
    //     font-size: 0.8em;
    //     line-height: 1.5;
    //   }
    //   .footer-title {
    //     color: #2a6bdb;
    //     font-size: 1em;
    //     padding-bottom: 3%;
    //   }
    // }
  }
}
</style>



