<template>
  <div class="box">
    <div class="main">
      <p class="title">功能架构总览</p>
      <p class="title2">·招采系统·</p>
      <div class="main-box hvr-glow wow animate__fadeIn">
       <div class="img"> <img src="../../../assets/images/857.png" alt=""></div>
      </div>
    </div>
    <div class="main">
      <p class="title">供方管理</p>
      <p class="title2">·招采系统·</p>
      <div class="main-box">
        <p class="text-head">通过建立分层、分级、分类的供方库库，整合优化供应商资源，提高工作效率</p>
       <div class="main-text">
          <div class="text hvr-backward wow animate__fadeIn">
            <div class="img">提供分层</div>
            <div class="text-box ">
              <p>战略采购供方</p>
              <p>集中采购供方</p>
              <p>一般供方</p>
              <p>一次性供方</p>
            </div>
          </div>
          <div class="text hvr-grow wow animate__fadeIn">
            <div class="img">供方分级</div>
            <div class="text-box ">
              <p>优秀(A)</p>
              <p>良好(B)</p>
              <p>合格(C)</p>
              <p>不合格</p>
            </div>
          </div>
          <div class="text  hvr-forward wow animate__fadeIn">
            <div class="img">供方分类</div>
            <div class="text-box" style="padding-bottom:10%;">
              <p>地产板块(一级7个，二级38个，三级197个)</p>
              <p>物业板块(一级4个，-级10个，三级31个)</p>
              <p>实业板块(一级1个，级2个， 三级32个)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">招标管理</p>
      <p class="title2">·招采系统·</p>
      <div class="center-box hvr-glow wow animate__fadeIn">
        <img src="../../../assets/images/858.png" class="hvr-forward" >
        <div class="center-text">
          <span>01</span>
          <p>纳招标流程节点可配，可实现不同招标方式差异化管理</p>
          <span>02</span>
          <p>招标过程合规性管控，违规事项红灯显示，直接审批上升一级，提升审批效率</p>
          <span>03</span>
          <p>招标过程仅采购经办人可查看，减少信息泄露风险</p>
          <span>04</span>
          <p>入围标准可视可控，招标过程透明，定标结果公示</p>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="title">招标过程内外网协同全程线上化</p>
      <p class="title2">·招采系统·</p>
      <div class="main-foot hvr-glow wow animate__fadeIn">
        <img src="../../../assets/images/859.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcurementSystem",
     data() {
    return {
    }
  },
  mounted() {
     this.$wow.init();
  },

};
</script>

<style lang="scss" scoped>
.animated {
  animation-duration: 1200ms        //动画持续时间
}
.click{
  cursor:pointer;
}
.box {
  width: 100%;
  height: 100%;
  .main {
    width: 70%;
    height: 100%;
    margin: 5% 0 0 15%;
    .title {
      color: #4f85e1;
        font-size: 18px;
      text-align: center;
    }
    .title2 {
      color: #9b9da1;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 5%;
    letter-spacing: 5px;
    }
    .main-box{
      
      .img{
        background-color: #fff;
        border-radius: 0.3em;
        img{
        width: 90%;
        padding: 5% 0;
        margin-left: 5%;
      }
      }
      .text-head{
        text-align: center;
        font-size: 0.9em;
      }
       .main-text {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        margin: 5% 0;
        .text {
          width: 30%;
          height: 50%;
          background-color: #fff;
          border-radius: 0.5em;
          // display: flex;
          .img {
            width: 100%;
            height: 100%;
            color: #2165d9;
            padding: 5% 0;
            text-align: center;
            background: url("../../../assets/images/B13-2.png") no-repeat;
            background-size: 15%;
            background-position: 50% 20%;
            margin: 5% 0 0 0;
          }
          .text-box {
            margin: 10% 0;
            text-align: center;

            p {
              font-size: 0.8em;
              line-height: 2;
            }
          }
        }
      }
    }
    .center-box{
      width: 100%;
       background-color: #fff;
       border-radius: 0.3em;
      img{
        width: 90%;
        padding-top: 2%;
        margin-left: 5%;
      }
      .center-text{
       width: 100;
       margin-left: 10%;
       padding-bottom: 5%;
       span{
        color: #2165d9;
        padding-bottom: 2%;
       }
       p{
        font-size: 14px;
        padding: 2% 0;
       }
      }
    }
    .main-foot{
      width: 100%;
       background-color: #fff;
       border-radius: 0.3em;
      img{
        width: 90%;
        padding: 5% 0;
        margin-left: 5%;
      }
    }
  }
}
</style>