<template>
  <div class="page">
    <div class="top">
      <div class="title">资产运营产品</div>
      <div class="eng">· 资产管理平台 ·</div>
    </div>
    <div class="content">
      <div class="left">
        <img src="../../../assets/images/left.png" class="icon hvr-push" />
      </div>
      <div class="text">
        <div class="title">简介/介绍</div>
        <div class="data wow animate__fadeIn">
          随着移动互联网、云计算、物联网和大数据技术的广泛应用，现代社会已经迈入全新信息化时代。通过建设集团资产运营平台，实现资源的集中及整合，
          构建统一的数据模型，提高数据的处理效率与共享程度。实现对企业内部数据和外部数据的分析挖掘，
          对内对外提供数据服务。为公司提供决策支持、资产营销、风险管控以及流程优化等支撑服务。
        </div>
      </div>
      <div class="right">
        <img src="../../../assets/images/right.png" class="icon hvr-push" />
      </div>
    </div>
    <div class="top">
      <div class="title">业务痛点</div>
      <div class="eng">BUSINESS PAIN POINTS</div>
    </div>
    <div class="business  ">
      <div 
        class="assets hvr-glow  wow animate__fadeInUp">
        <img src="../../../assets/images/B13.png" class="point-icon hvr-push" />
        <div class="point-bottom">
          <div class="point-title">资产管理难</div>
          <div class="point-data">
            资产数据可知、资产数据可控、决策数据可查。资产规模巨大，业态复杂;历史遗留问题多;
            权属/处置过程复杂;资产运营风险不可控,因资产特殊性，项目经营监管压力大,确保国有资产不流失;
            资产增值需求日益凸显,国有资本投资、运营、改革、监管、考核机制加速试点
          </div>
        </div>
      </div>
      <div  class="assets hvr-glow wow animate__fadeInUp">
        <img src="../../../assets/images/B14.png" class="point-icon hvr-push" />
        <div class="point-bottom">
          <div class="point-title">招商管理难</div>
          <div class="point-data">
            招商过程管理难、客户资源储备难、代理伙伴管理难;客户信息缺失∶
            因为缺少统一标准的客户信息管理机制，造成客户信息缺失，价值降低,间接造成客户资源的浪费;接访过程缺少标准︰
            接待客户时招商经理自由发挥，如遇到经验不足的招商经理发挥失当将间接导致客户流失。
            接访过程中基本靠口述，缺少整体、完整、专业的介绍，客户对于产品的感知不够清晰;转化率低︰
            未能将客户需求与目前运营的空间、房源进行有效的匹配，客户转化率低;客户经营缺少标准∶每个招商经理按照自己的经验和习惯来经营客户，
            对于缺少经验﹔的招商经理可能会造成客户流失，客户流失缺少预警:对于缺少有效经营从而可能造成客户流失的情况缺少预警;沟通过程不可控︰
            招商经理与客户的沟通过程无法感知，从而无法对整个过程进行改进和提升
          </div>
        </div>
      </div>
      <div  class="assets hvr-glow wow animate__fadeInUp">
        <img src="../../../assets/images/B14.png" class="point-icon hvr-push" />
        <div class="point-bottom">
          <div class="point-title">运营管理难</div>
          <div class="point-data">
            租赁合同管理难、财务费用管控难、客户服务管理难;业务繁杂,效率低,租赁状态转换频繁，无法精准统计﹔
            款项催缴占用人员较多精力;数据查询困难,没有信息化系统，现有租赁合同都是通过线下纸质文档保管，查询困难，岗位职责不清,
            租赁合同签署和变更流程不规范，权责不清,容易出现互相扯皮或是延期的情况发生﹔指标不统―,集团要求的管理指标统计，
            缺乏统一（与财务）、及时和有效
          </div>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="title">解决方案</div>
      <div class="eng">THE SOLUTION</div>
    </div>
    <div class="case wow animate__fadeInUp">
      <div  class="case-list hvr-grow ">
        <img src="../../../assets/images/D04.png" class="case-icon hvr-pop" />
        <div class="case-title">盘清资产-载体管理</div>
        <div class="case-data">
          实现资产全生命周期管控，全盘掌握资产，通过载体可视化管理实现资产档案数字化，通过处置过程流程化，
          业务标准化实现资产处置流程化,通过资产价格动态评估，资产信息可视化实现资产评估在线化
        </div>
      </div>
      <div  class="case-list hvr-grow ">
        <img src="../../../assets/images/D04-2.png" class="case-icon hvr-pop" />
        <div class="case-title">盘活资产-资产运营</div>
        <div class="case-data">
          搭建数据化运营决策体系，通过空间资产招商规划.制定招商目标计划、出台招商优惠政策、确定资产营销价格、
          整合资源营销推广和招商服务持续跟进的方式盘活资产，实现招商在线、租约管理以及业财一体化
        </div>
      </div>
      <div  class="case-list hvr-grow ">
        <img src="../../../assets/images/D04-3.png" class="case-icon hvr-pop" />
        <div class="case-title">服务升级-升级运营</div>
        <div class="case-data">
          在线化客户服务体系，为入驻客户和业主提供线上服务，包含物业服务、智慧Al服务、品质巡检管理、
          设备管理以及智慧物业应用-用电/水/气安全,让资产增值提速
        </div>
      </div>
    </div>
    <div class="top">
      <div class="title">业务价值</div>
      <div class="eng">THE BUSINESS VALUE</div>
    </div>
    <div class="worth">
      <img
        src="../../../assets/images/D03.png"
        class="worth-icon hvr-backward"
      />
      <div class="worth-left hvr-forward">
        <div class="left">
          <div class="worth-title">资产管理</div>
          <div class="worth-data">
            实现全生命周期管控,全盘掌握资产信息以及资产可视化
          </div>
          <div class="worth-title">资产运营</div>
          <div class="worth-data">基于业主生命周期的资产管理系统平台</div>
          <div class="worth-title">租约管理</div>
          <div class="worth-data">合同签约、变更、续签、退租流程化和标准化</div>
          <div class="worth-title">收费管理</div>
          <div class="worth-data">实现业财金税—体化，智能计费，财务更轻松</div>
          <div class="worth-title">运营管理</div>
          <div class="worth-data">
            企业入驻、企业迁出,智慧物业应用-用电/水/气安全，提升服务效率，形成服务闭环
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetPlatform",
  mounted() {
    this.$wow.init();
  },
};
</script>

<style scoped lang="scss">
.animated {
  animation-duration: 1500ms        //动画持续时间
}
.page {
  width: 100%;
  height: 100%;
  background-color: #eff3fb;
  text-align: center;
  padding: 30px 0;

  .top {
    margin-bottom: 40px;

    .title {
      color: #4f85e1;
      font-size: 18px;
    }

    .eng {
      letter-spacing: 5px;
      color: #d1d4d9;
      margin-top: 15px;
      font-size: 12px;
    }
  }

  .content {
    width: 70%;
    margin-left: 15%;
    height: 300px;
    position: relative;

    .icon {
      height: 60px;
      width: 60px;
    }

    .left {
      position: absolute;
    }

    .right {
      position: absolute;
      right: 0;
      top: 30%;
    }

    .text {
      padding: 0 40px;

      .title {
        font-size: 22px;
        text-align: left;
        padding: 30px 0;
      }

      .data {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: #656565;
      }
    }
  }

  .business {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 50px;

    .assets {
      background-color: #fff;
      display: flex;
      margin: 20px 0;
      padding: 20px;
      border-radius: 6px;

      .point-icon {
        height: 30px;
        width: 30px;
      }

      .point-bottom {
        text-align: left;
        margin-left: 20px;

        .point-title {
          color: #656565;
        }

        .point-data {
          color: #bcbcbc;
          font-size: 14px;
          margin-top: 10px;
          line-height: 20px;
        }
      }
    }
  }

  .case {
    width: 70%;
    margin-left: 15%;
    display: flex;
    margin-bottom: 50px;

    .case-list:last-child {
      margin-right: 0;
    }

    .case-list {
      border-radius: 6px;
      margin-right: 20px;
      width: 33%;
      background-color: #fff;
      padding: 30px 20px;
      text-align: left;
    }

    .case-icon {
      height: 30px;
      width: 30px;
    }

    .case-title {
      color: #4e4f4e;
      margin: 15px 0;
    }

    .case-data {
      color: #bcbcbc;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .worth {
    display: flex;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 50px;
    .worth-icon {
      height: 340px;
      width: 80%;
    }
    .worth-left {
      height: 340px;
      background-color: #fff;
      text-align: left;
      font-size: 14px;
      .left {
        padding: 40px 20px;
      }
      .worth-title {
        color: #4f85e1;
        padding: 10px 0;
      }
      .worth-data {
        color: #6e6e6e;
      }
    }
  }
}
</style>
